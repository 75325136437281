.Sidebar__container {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-top: 2px;
  max-height: 100%;
  width: 20%;
  flex: 1 1 auto;
}

@media screen and (max-width: 844px) {
  .Sidebar__container {
    display: None
  }
}

.Sidebar__tab {
  flex: 0 0 auto;
  border: 1px solid #000;
  width: 50%;
  cursor: pointer;
  text-align: center;
  line-height: 25px;
}

.Sidebar__tab-true {
  background-color: #666;
}

.Sidebar__tab:hover {
  background: #666;
  transition: all 1s;
}

.Sidebar__content--log {
  height: 100%;
  overflow-y: auto;
}

.Sidebar__content--chat {
  height: 100%;
  overflow-y: auto;
}

.Sidebar__content {
  width: 100%;
  border: 0.01em solid #999;
  border-radius: 0.5em;
  flex: 1 1 auto;
  margin-left: 2px;
  margin-top: 5px;
  max-height: inherit;
  box-sizing: border-box;
  overflow-y: auto;
}

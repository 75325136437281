.GameOverModal {
  top: 30%;
  bottom: 30%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  width: 100%;
  background-color: white;
  opacity: 100%;
  font-size: 1.2em;
}

.GameOverModal__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

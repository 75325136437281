.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
}

.Login__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Login__input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 15px;
}

.Login__extra {
  padding-left:10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

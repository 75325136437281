.Battlefield-container {
  /* border: 0.1em solid black; */
  margin-right: 2px;
  flex: 1 1 70%;
  display: flex;
}

.Battlefield__player-container {
  max-height: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  width: 100%;
}

.Battlefield-Player1 {
  display: flex;
  flex-flow: column-reverse;
  flex: 0 1 50%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.05);
}

.Battlefield-Player2 {
  display: flex;
  flex-flow: column;
  flex: 0 1 50%;
  height: 100%;
  align-items: flex-end;
  background-color: rgba(0, 0, 255, 0.05);
}

.TurnPhase {
  margin: 2px;
  margin-left: 4px;
}

.PlayerDetails-active {
  margin-bottom: 5px;
  margin-left: 10px;
}

.PlayerDetails-nonactive {
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
}

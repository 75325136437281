.ActionModal__container {
  align-items: center;
  justify-content: center;
  display: flex;
}

.ActionModal {
  margin-top: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ActionModal__element {
  background-color: rgba(0, 0, 0, 0.5);
}

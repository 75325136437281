.DescriptorsModal {
  top: 30%;
  bottom: 30%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  font-size: 1.2em;
}

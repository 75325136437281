html, body {
  height:100%;
  background-color: #eee;
  font-size: 11px;
  /*font-family: monospace;*/
  font-family: 'Josefin Sans', sans-serif;

}

#root {
  height:100%;
}

.nav-container {
  margin-top: 2px;
  margin-left: 2px;
  display: flex;
}

.nav {
  flex: 1 1 auto;
}

.gameid {
  flex: 0 0 auto;
  margin-right: 10px;
  margin-top: 5px;
}

.Scene {
  height:96vh;
}

.Scene-container {
  display: flex;
  height: 97%;
}

.Scene__share {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Scene__share--telegram {
  padding-right: 5px;
  padding-top: 4px;
}

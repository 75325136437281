.Tray {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  min-height: 200px;
}


@media screen and (max-width: 844px) {
  .Tray {
    min-height: 150px;
  }
}

.Tray__cards {
  display: flex;
  flex-direction: row;
  overflow-y: clip;
  overflow-x: scroll;
}

.HandContainer {
  overflow-y: clip;
  overflow-x: scroll;
  max-height: 210px;
  /*border: 1px solid black;*/
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  margin-right: 2px;
  margin-bottom: 3px;
}

@media screen and (max-width: 844px) {
  .HandContainer {
    max-height: 155px;
  }
}

.Hand {
  width: 100%;
  overflow-y: clip;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  margin: 2px;
  min-height: 210px;
}

/* TODO We could probably unify she two slots here */
.Hand__slot {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  min-width: 150px;
  margin-bottom: 2px;
}

.Tray__slot {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  min-width: 150px;
  margin-bottom: 2px;
}

@media screen and (max-width: 844px) {
  .Hand__slot {
    min-width: 110px;
  }
  .Tray__slot {
    min-width: 110px;
  }

}

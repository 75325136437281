.Title {
  text-align: center;
}

.Title__text {
  font-size: 100px;
  margin: 0;
}

.Title__version {
  margin-bottom: 10em;
}

.TeamEditor__mages {
  display: flex;
}

.TeamEditor__mages--mage {
  min-height: 150px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.TeamEditor__mages--mage--empty {
  cursor: pointer;
}

.TeamEditor__mages--change-mage {
  cursor: pointer;
  border: solid 1px #000;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.TeamEditor__mages--change-deck {
  cursor: pointer;
  border: solid 1px #000;
  margin-left: 5px;
  margin-right: 5px;
}

.Message__list {
  max-height: 100%;
  margin:2px;
  flex: 1 0 80%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-content: flex-start;
}

.Message__message {
  margin: 0px;
  padding: 0px;
  overflow-wrap: break-word;
  white-space: initial;
}

.Message__message-last {
  min-height: 2000px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: initial;
}

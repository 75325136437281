.Chat__container {
  width: 100%;
  max-height: 100%;
  flex-flow: column;
  display: flex;
}

.Chat__input-container {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
}

.Chat__enter {
  margin:2px;
  flex: 1 0 100px;
  min-height: 100px;
}

.Chat__textarea {
  resize: none;
  padding-left: 0.3em;
  padding-right: 0.3em;
  width: 100%;
  flex: 1 0 auto;
}
